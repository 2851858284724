import { render, staticRenderFns } from "./MainPage.vue?vue&type=template&id=3a9cca16&scoped=true"
import script from "./MainPage.vue?vue&type=script&lang=js"
export * from "./MainPage.vue?vue&type=script&lang=js"
import style0 from "./MainPage.vue?vue&type=style&index=0&id=3a9cca16&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a9cca16",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\product-yangzhou\\view\\ms-vue-occupational-health\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a9cca16')) {
      api.createRecord('3a9cca16', component.options)
    } else {
      api.reload('3a9cca16', component.options)
    }
    module.hot.accept("./MainPage.vue?vue&type=template&id=3a9cca16&scoped=true", function () {
      api.rerender('3a9cca16', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/maintenance/hazard_factor_standard/MainPage.vue"
export default component.exports