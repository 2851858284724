<template>
  <div class='hazard-factor-standard-manage'>
    <el-form class='zwx-form' key='msForm' :model='msForm' ref='msForm' label-position='right' @submit.native.prevent>
      <div class='edit-form'>
        <el-form-item label='类别：' prop='hazardsSortLists' label-width='50px'>
          <el-checkbox-group class='zwx-checkbox-group' v-model='msForm.hazardsSortLists'>
            <el-checkbox class='zwx-checkbox' :label='1'>粉尘</el-checkbox>
            <el-checkbox class='zwx-checkbox' :label='2'>化学</el-checkbox>
            <el-checkbox class='zwx-checkbox' :label='3'>物理</el-checkbox>
            <el-checkbox class='zwx-checkbox' :label='4'>放射</el-checkbox>
            <el-checkbox class='zwx-checkbox' :label='5'>生物</el-checkbox>
            <el-checkbox class='zwx-checkbox' :label='6'>其他</el-checkbox>
          </el-checkbox-group>
<!--          <el-select class='zwx-select' v-model='msForm.hazardsSortLists' multiple placeholder='请选择'>-->
<!--            <el-option-->
<!--              v-for='item in options'-->
<!--              :key='item.value'-->
<!--              :label='item.label'-->
<!--              :value='item.value'>-->
<!--            </el-option>-->
<!--          </el-select>-->
        </el-form-item>
        <el-form-item label='名称：' label-width='50px'>
          <el-input class='zwx-input' style='height: 30px;width: 160px!important;' placeholder='请输入'
                    v-model.trim='msForm.hazardsName' clearable></el-input>
        </el-form-item>
        <el-form-item label='编码：' label-width='50px'>
          <el-input class='zwx-input' style='height: 30px;width: 160px!important;' placeholder='请输入'
                    v-model.trim='msForm.codeNo' clearable></el-input>
        </el-form-item>
        <el-form-item label='CAS：' label-width='50px'>
          <el-input class='zwx-input' style='height: 30px;width: 160px!important;' placeholder='请输入'
                    v-model.trim='msForm.cas' clearable></el-input>
        </el-form-item>
        <el-form-item label='状态：' label-width='50px'>
          <el-checkbox-group class='zwx-checkbox-group' v-model='msForm.ifEnableLists'>
            <el-checkbox class='zwx-checkbox' :label='1'>启用</el-checkbox>
            <el-checkbox class='zwx-checkbox' :label='0'>停用</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-button class='zwx-button zwx-button-icontext-28' type='primary' icon='el-icon-search' @click='search(1)'>查询
        </el-button>
        <el-button class='zwx-button zwx-button-icontext-28' icon='el-icon-plus' @click='add'>添加</el-button>
      </div>
    </el-form>
    <el-table class='zwx-table sortable-table' style='width: 100%' row-key='rid' key='backlogForm'
              :data='dataList' stripe border tooltip-effect='light'>
      <el-table-column prop='codeNo' label='编码' width='100' header-align='center' align='left'>
      </el-table-column>
      <el-table-column prop='levelCodeNo' label='层级编码' width='150' header-align='center' align='center'
                       :show-overflow-tooltip='true'>
      </el-table-column>
      <el-table-column prop='hazardsSort' label='类别' width='80' header-align='center' align='center'>
        <template slot-scope='scope'>
          {{ scope.row.hazardsSort === 1 ? '粉尘' : scope.row.hazardsSort === 2 ? '化学' : scope.row.hazardsSort === 3 ? '物理' : scope.row.hazardsSort === 4 ? '放射' : scope.row.hazardsSort === 5 ? '生物' : scope.row.hazardsSort === 6 ? '其他' : ''
          }}
        </template>
      </el-table-column>
      <el-table-column prop='hazardsName' label='名称' width='250' header-align='center' align='left'>
      </el-table-column>
      <el-table-column prop='cas' label='CAS' width='180' header-align='center' align='center'>
      </el-table-column>
      <el-table-column prop='ifEnable' label='状态' width='100' header-align='center' align='center'>
        <template slot-scope='scope'>
          <div class='zwx-circle-text zwx-circle-normal' v-if='scope.row.ifEnable'>启用</div>
          <div class='zwx-circle-text zwx-circle-finish' v-else>停用</div>
        </template>
      </el-table-column>
      <el-table-column label='操作' header-align='left' align='left' fixed='right' :min-width='160'>
        <template slot-scope='scope'>
          <el-button class='zwx-button zwx-button-text-26' type='text' icon='el-icon-top-right'
                     @click='edit(scope.row)'>编辑
          </el-button>
          <el-button class='zwx-button zwx-button-text-26' :class=" 'zwx-button-danger' "
                     type='text'
                     @click="$system.msgbox(scope.row, '提示',  '确定要删除吗？', '确定', '取消', del)">
            删除
          </el-button>
          <el-button class='zwx-button zwx-button-text-26' :class="scope.row.ifEnable ? 'zwx-button-danger' : ''"
                     type='text'
                     @click="$system.msgbox(scope.row, '提示', scope.row.ifEnable ? '确定要停用吗？' : '确定要启用吗？', '确定', '取消', statusStop)">
            {{ scope.row.ifEnable ? '停用' : '启用' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination class='normal-pagination' :parentPage='currentPage'
                   :pageSize='pageSize' :total='total'
                   @currentChange='search' />
    <base-dialog ref='msDialog' class='edit-dialog' :title='title' @opened='dialogOpened()' @determine='submit()'
                    @cancel='cancelDepartmentDialog' :pagination='false' style='width: 836px!important;'
                    :checkName1='chackName1' :type1='type1' :check-name2='chackName2'>
      <el-form ref='userForm' class='zwx-form' :model='msFormDialog' :rules='rules'>
        <div class='edit-form'>
          <div class='edit-form'>
            <el-form-item label='类别：' label-width='110px' prop='hazardsSort'>
              <el-radio-group class='zwx-radio-group' style='min-width:68px!important'
                              v-model='msFormDialog.hazardsSort'>
                <el-radio class='zwx-radio' :label='1'>粉尘</el-radio>
                <el-radio class='zwx-radio' :label='2'>化学</el-radio>
                <el-radio class='zwx-radio' :label='3'>物理</el-radio>
                <el-radio class='zwx-radio' :label='4'>放射</el-radio>
                <el-radio class='zwx-radio' :label='5'>生物</el-radio>
                <el-radio class='zwx-radio' :label='6'>其他</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class='edit-form'>
            <el-form-item label='名称：' label-width='110px' prop='hazardsName'>
              <el-input class='zwx-input' style='width:350px !important'
                        placeholder='' v-model='msFormDialog.hazardsName' maxlength='50' clearable />
            </el-form-item>
          </div>
          <div>
            <el-form-item label='拼音码：' label-width='110px'>
              <el-input class='zwx-input' style='width:350px !important' placeholder=''
                        v-model.trim='msFormDialog.pinyinCode' maxlength='50' clearable></el-input>
            </el-form-item>
          </div>
          <div class='edit-form'>
            <el-form-item label='CAS：' label-width='110px' prop='cas'>
              <el-input class='zwx-input' style='width:350px !important'
                        placeholder='' v-model='msFormDialog.cas' maxlength='20' clearable />
            </el-form-item>
          </div>
          <div class='edit-form'>
            <el-form-item label='编码：' label-width='110px' prop='codeNo'>
              <el-input class='zwx-input' style='width:350px !important'
                        placeholder='' v-model='msFormDialog.codeNo' maxlength='20' clearable />
            </el-form-item>
          </div>
          <div class='edit-form'>
            <el-form-item label='层级编码：' label-width='110px' prop='levelCodeNo'>
              <el-input class='zwx-input' style='width:350px !important'
                        placeholder='' v-model='msFormDialog.levelCodeNo' maxlength='20' clearable />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'HazardFactorStandard',
  data() {
    return {
      api: this.$store.state.api,
      msForm: {
        hazardsSortLists: [],
        hazardsName: '',
        codeNo: '',
        cas: '',
        ifEnableLists: [1],
      },
      options: [
        {
          value: '1',
          label: '粉尘',
        }, {
          value: '2',
          label: '化学',
        }, {
          value: '3',
          label: '物理',
        }, {
          value: '4',
          label: '放射',
        }, {
          value: '5',
          label: '生物',
        }, {
          value: '6',
          label: '其他',
        }],
      dataList: [],
      total: 0,
      pageSize: 18,
      currentPage: 1,
      //单位id
      employerId: '',
      /**
       * 弹框部分
       **/
      title: '添加危害因素',
      //弹框按钮名称
      chackName1: '确认',
      chackName2: '取消',
      type1: 'primary',
      msFormDialog: {
        rid:'',
        hazardsSort: '',
        hazardsName: '',
        codeNo: '',
        cas: '',
        levelCodeNo:'',
        pinyinCode:''
      },
      rules: {
        hazardsSort: [
          { required: true, message: '请选择类别', trigger: ['change', 'blur'] },
        ],
        hazardsName: [{ required: true, message: '请输入名称', trigger: [ 'blur'] }],
        codeNo: [{ required: true, message: '请输入编码', trigger: ['blur'] }],
        levelCodeNo: [{ required: true, message: '请输入层级编码', trigger: ['blur'] }],
      },
    }
  },
  computed: {},
  watch: {},
  activated() {
    if (this.$route.params.fresh) this.search()
  },
  created() {
  },
  mounted() {
    let url = this.$route.query
    if (url.employerID != undefined) {
      this.employerId = url.employerID
    }
    // this.search(1)
  },
  methods: {
    //查询
    search(currentPage) {
      this.currentPage = currentPage
      this.$emit('loading', true)
      let data = {
        currentPage: currentPage,
        hazardsSortStr: this.msForm.hazardsSortLists.join(',') || null,
        ifEnableStr: this.msForm.ifEnableLists.join(',') || null,
        hazardsName: this.msForm.hazardsName || null,
        codeNo: this.msForm.codeNo|| null,
        cas: this.msForm.cas|| null,
      }
      this.$system.get(this.api + '/oh/repository/getHazardsFactorStandardList-1', data, true, true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.dataList = data.hazardsFactorStandardList
            this.total = data.hazardsFactorStandardCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        }, this.error)
    },
    //添加
    add() {
      this.$refs.msDialog.show(true)
    },
    //编辑
    edit(row) {
      this.title = '编辑危害因素'
      let data = {
        rid: row.rid,
      }
      this.$system.get(this.api + '/oh/repository/getHazardsFactorStandard-1', data, true, true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.msFormDialog = data.hazardsFactorStandard
            this.$refs.msDialog.show(true)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        }, this.error)
    },
    //删除
    del(row) {
      let data = {
        rid: row.rid,
      }
      this.$system.postJson(this.api + '/oh/repository/deleteHazardsFactorStandardByID-1', data, true, true, (data) => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.search(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        (data) => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        })
    },
    //状态
    statusStop(row){
      let data = {
        rid: row.rid,
        ifEnable:row.ifEnable,
      }
      this.$system.postJson(this.api + '/oh/repository/enableOrDisableHazardsFactorStandardByID-1', data, true, true, (data) => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.search(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        (data) => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        })
    },
    /**
     * 弹框部分
     */
    dialogOpened() {

    },
    //提交
    submit() {
      this.$refs['userForm'].validate(valid => {
        if (valid) {
          this.$emit('loading', true)
          let data = {
            ...this.msFormDialog,
          }
          this.$system.postJson(this.api + '/oh/repository/saveOrUpdateHazardsFactorStandard-1', data, true, true, (data) => {
              this.$emit('loading', false)
              if (data.type == '00') {
                this.$system.notify('成功', data.mess, 'success')
                this.$refs.msDialog.show(false)
                this.$set(this.msFormDialog, 'hazardsSort', '')
                this.$set(this.msFormDialog, 'rid', '')
                this.$set(this.msFormDialog, 'hazardsName', '')
                this.$set(this.msFormDialog, 'codeNo', '')
                this.$set(this.msFormDialog, 'cas', '')
                this.$set(this.msFormDialog, 'levelCodeNo', '')
                this.$set(this.msFormDialog, 'pinyinCode', '')
                this.search(1)
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            (data) => {
              this.$emit('loading', false)
              this.$system.notify('错误', data.mess, 'error')
            })
        }
      })
    },
    //取消
    cancelDepartmentDialog() {
      this.$refs.msDialog.show(false)
      this.msFormDialog.hazardsSort = ''
      this.msFormDialog.hazardsName = ''
      this.msFormDialog.codeNo = ''
      this.msFormDialog.cas = ''
      this.msFormDialog.rid = ''
      this.msFormDialog.levelCodeNo = ''
      this.msFormDialog.pinyinCode = ''
    },
  },
}
</script>

<style lang='less' scoped>
.edit-dialog /deep/ .el-dialog {
  min-width:550px!important;
  width: 550px !important;
}

.edit-form {
  /deep/ .zwx-select > .el-input.el-input--suffix > .el-input__inner {
    width: 420px !important;
  }
}

</style>

<style lang='less'>

</style>